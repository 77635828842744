import React from "react";
import MoneyChanger from "../../assets/tipsAndTricks/AyuMasAgung.jpg";

const MoneyChangerComp = () => {
  return (
    <section className="flex flex-col py-5 w-full overflow-hidden items-center gap-14 text-black">
      <div className="about-visa">
        <div className="m-14 poppins">
          <div className="flex justify-start mx-10">
            <div className=" w-[65px] h-[40px] bg-[#198754]" />
            <div className="poppins text-[#198754] m-5 text-2xl relative w-fit font-semibold mt-[-1.00px]">
              Money Changer and Simcard
            </div>
          </div>
          <div className="lg:flex justify-center gap-20 sm:mx-10 md:mx-10">
            <div className="poppins mt-10 font-normal">
              <p className="text-transparent leading-loose">
                <p className="font-normal text-transparent">
                  <p className="text-transparent mb-10 leading-loose">
                    <span className="text-[#198754] font-bold ">
                      Ayu Masagung Money Changer
                      <br />
                    </span>
                    <span className="text-black">
                      <p>
                        <span className="font-bold">Address: </span>Jl. Kramat
                        Kwitang No.37-38, RT.1/RW.1, Kwitang, Kec. Senen, Kota
                        Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10420
                      </p>
                      <span className="font-bold">Google Maps: </span>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://goo.gl/maps/pK3gsW5inWJp4hwj9 "
                      >
                        {" "}
                        https://goo.gl/maps/pK3gsW5inWJp4hwj9
                      </a>{" "}
                      <br></br>
                    </span>
                  </p>
                  <div className="flex flex-col items-center gap-10 mb-4">
                    <div className="relative my-16 lg:hidden">
                      <div className="rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                      <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                      <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
                      <img
                        className="absolute rounded-[30px] object-cover object-center h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
                        alt="Ayu Mas Agung"
                        src={MoneyChanger}
                      />
                    </div>
                  </div>
                  <p className="text-transparent mb-10 leading-loose">
                    <span className="text-[#198754] font-bold ">
                      Simcard
                      <br />
                    </span>
                    <span className="text-black">
                      <p>Check out our recommendations:</p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.airsim.com.sg/"
                      >
                        <span className="font-bold">Airsim </span>
                      </a>
                      |
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://yesim.app/"
                      >
                        <span className="font-bold"> Yesim </span>
                      </a>
                      |
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.airalo.com/"
                      >
                        <span className="font-bold"> Airalo</span>
                      </a>
                    </span>
                    <br />
                    <span className="text-black">
                      <p>
                        Our selection of SIM cards includes leading providers in
                        Indonesia such as{" "}
                        <span className="text-[#198754] font-bold ">
                          Telkomsel, XL, and Indosat,{" "}
                        </span>
                        ensuring you get the best coverage and service.
                        Conveniently available for purchase at the airport or
                        SIM card stores.
                      </p>
                    </span>
                  </p>
                </p>
              </p>
            </div>
            <div className="lg:block hidden relative my-8 content-end">
              <div className="rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
              <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
              <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
              <img
                className="absolute rounded-[30px] object-cover object-center h-[20vh] w-[35vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
                alt="Ayu Mas Agung"
                src={MoneyChanger}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MoneyChangerComp;
