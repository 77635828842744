import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "./pages/Homepage";
import StudentExchange from "./pages/StudentExchange";
import Lecturer from "./pages/LecturerOutbound";
import ContactUs from "./pages/ContactUs";
import OurCampus from "./pages/OurCampus";
import TipsAndTricks from "./pages/TipsAndTricks";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/student-exchange" element={<StudentExchange />} />
        <Route path="/tips-and-tricks" element={<TipsAndTricks />} />
        <Route path="/our-campus" element={<OurCampus />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/lecturer-outbound" element={<Lecturer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
