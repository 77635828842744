import React from "react";
import requirement from "../../assets/studentExchange/requirements.png";
import UIDark from "../../assets/homepage/whyui-dark.png";

const Requirements = () => {
  return (
    <div className="requirements">
      <div className="m-10 poppins p-10">
        <div className="flex justify-start">
          <div className=" w-[65px] h-[40px] bg-[#198754]" />
          <div className="poppins text-[#198754] m-5 text-2xl relative font-semibold w-fit mt-[-1.00px]">
            Requirements
          </div>
        </div>
        <div className="lg:flex justify-center gap-20">
          <div className="poppins mt-10 font-normal">
            <p className="  mb-10 leading-loose text-black">
              Below are the requirements needed to apply for the student
              exchange program:
            </p>
            <p className="font-normal text-transparent mb-10">
              <ol className="leading-loose text-black list-decimal">
                <li>Application Form (provided in the link)</li>
                A completion of the application form as a requirement to certify for nominates and approval for the student exchange program to the Faculty of Dentistry Universitas Indonesia.
                <br/>Link of Form:
                <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://drive.google.com/file/d/1LzkPPe3Md94cKWTYP128HxbDji0--Ggw/view?usp=drive_link"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        PDF{" "}
                      </span>
                      |
                </a>
                <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://docs.google.com/document/d/1_9SEP3_X_7gUiPCLbvHmq8IBwJwnB-P6/edit?usp=sharing&ouid=114890051005748758295&rtpof=true&sd=true"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Word{" "}
                      </span>
                </a>
                <li>Official Academic Transcript (English Version)</li>
                  The documents were authenticated with the official stamp of
                  the sister university.
                <li>Certificate of Enrollment (English Version)</li>
                  The documents needed to certify as an active student in the
                  sister university.
                <li>
                  Recommendation letter/nomination letter from sister university
                </li>
                <li>Certificate of English Proficiency</li>
                  English Proficiency provided by TOEFL, IELTS or Duolingo with
                  minimum scores of TOEFL: 550 (paper-based), IELTS: 5.5, iBT:
                  70, and Duolingo: 100 with validity of 2 years from test date.
                <li>
                  Scanned document of passport with cover contained personal
                  information and passport validity of minimum 24 months.
                </li>
                <li>
                  Certificate of Health by the doctor from the applicant's
                  country.
                </li>
              </ol>
            </p>
            <p className="mb-10 leading-loose text-black">
            After we receive the application, we will process the Letter of
            Acceptance from the Faculty.
            </p>
          </div>
          <div className="flex flex-col items-center gap-10 mb-4">
          <div className="relative my-16">
            <div className="rounded-[30px] h-[40vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
            <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
            <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
            <img
              className="absolute rounded-[30px] object-cover object-center h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
              alt="Visa"
              src={requirement}
            />
          </div>
            <button className="bg-[#198754] text-white rounded-xl font-extrabold px-7 py-3">
              <a
                href="https://docs.google.com/document/d/1_9SEP3_X_7gUiPCLbvHmq8IBwJwnB-P6/edit?usp=sharing&ouid=114890051005748758295&rtpof=true&sd=true"
                target="_blank"
                rel="noreferrer"
              >
                Application Form
              </a>
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Requirements;
