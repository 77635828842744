import React from "react";
import Transport from "../../assets/tipsAndTricks/transportations.png";

const TravelTips = () => {
  return (
    <section className="flex flex-col py-20 w-full overflow-hidden items-center gap-14 text-black">
      <div className="flex flex-col gap-3 items-center">
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold text-center py-2 text-[#198754]">
          Tips and Tricks
        </h1>
        <div className="w-28 bg-[#198754] h-2  rounded-full" />
      </div>
      <div className="about-visa">
        <div className="m-10 poppins">
          <div className="flex justify-start mx-10">
            <div className=" w-[65px] h-[40px] bg-[#198754]" />
            <div className="poppins text-[#198754] m-5 text-2xl font-semibold relative w-fit mt-[-1.00px]">
              Travel Tips and Transportations After Arrival
            </div>
          </div>
          <div className="lg:flex justify-center gap-20 sm:mx-10 md:mx-10">
            <div className="poppins mt-10 font-normal">
              <p className="text-transparent mb-10 leading-loose">
                <span className="text-black">
                  Upon your arrival, we will pick up students at the airport. On
                  your daily activities, you can use the public transportations
                  that we recommend:
                  <br />
                </span>
              </p>
              <p className="font-normal text-transparent my-5">
                <span className="font-bold text-[#198754] mb-3">
                  1. Grab
                  <br />
                </span>
                <p className="text-transparent mb-10 leading-loose">
                  <span className="text-black">
                    Please check Grab's feature here:
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.grab.com/id/en/ "
                    >
                      {" "}
                      https://www.grab.com/id/en/
                    </a>{" "}
                    <br></br>
                    Download the app via
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.grabtaxi.passenger&hl=en&gl=US&pli=1"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Google Play{" "}
                      </span>
                      |
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/id/app/grab-taxi-food-delivery/id647268330"
                    >
                      <span className="font-bold text-black"> App Store </span>
                    </a>
                    |
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://appgallery.huawei.com/#/app/C100447517"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Huawei App Gallery{" "}
                      </span>
                    </a>
                  </span>
                </p>
              </p>
              <p className="font-normal text-transparent my-5">
                <span className="font-bold text-[#198754] mb-3">
                  2. Gojek
                  <br />
                </span>
                <p className="text-transparent mb-10 leading-loose">
                  <span className="text-black">
                    Please check Gojek's feature here:
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.gojek.com/en-id/products/ "
                    >
                      {" "}
                      https://www.gojek.com/en-id/products/
                    </a>{" "}
                    <br></br>
                    Download the app via
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/id/app/gojek/id944875099"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Google Play{" "}
                      </span>
                      |
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.gojek.app&hl=en&gl=US"
                    >
                      <span className="font-bold text-black"> App Store </span>
                    </a>
                  </span>
                </p>
              </p>
              <p className="font-normal text-transparent my-5">
                <span className="font-bold text-[#198754] mb-3">
                  3. Bluebird
                  <br />
                </span>
                <p className="text-transparent mb-10 leading-loose">
                  <span className="text-black">
                    Please check Blue Bird's feature here:
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.bluebirdgroup.com/"
                    >
                      {" "}
                      https://www.bluebirdgroup.com/
                    </a>{" "}
                    <br></br>
                    Download the app via
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.seatech.bluebird&hl=en&gl=US"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Google Play{" "}
                      </span>
                      |
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/id/app/mybluebird-taxi/id476533261"
                    >
                      <span className="font-bold text-black"> App Store </span>
                    </a>
                    |
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://appgallery.huawei.com/#/app/C101446927"
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Huawei App Gallery{" "}
                      </span>
                    </a>
                  </span>
                </p>
              </p>
            </div>
            <div className="flex flex-col items-center gap-10 mb-4">
              <div className="relative my-16">
                <div className="rounded-[30px] h-[40vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
                <img
                  className="absolute rounded-[30px] object-cover object-center h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
                  alt="Transport"
                  src={Transport}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TravelTips;
