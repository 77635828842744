import React from "react";
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full py-16 px-4 text-gray-300 flex justify-center bg-[#198754]">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-white">
          FKG UI INFO PAGE
        </h1>
        <p className="py-4 text-gray-200">
          © 2023 FKG Universitas Indonesia. All rights reserved.
        </p>
        <div className="flex justify-center my-6 space-x-4">
          {/* <FaFacebookSquare size={30} />
          <FaInstagram size={30} />
          <FaTwitterSquare size={30} />
          <FaGithubSquare size={30} />
          <FaDribbbleSquare size={30} /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
