import React from "react";
import FKG1 from "../../assets/homepage/fkg1.png";
import FKG2 from "../../assets/homepage/fkg2.png";
import FKG3 from "../../assets/homepage/fkg3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Card = ({ title, href, picture }) => (
  <div className="relative w-full flex flex-col gap-2">
    <img
      src={picture}
      className="w-full h-auto rounded-2xl object-cover"
      alt="Programs"
    />
  </div>
);

const Students = () => {
  return (
    <div className="students m-10">
      <section className="flex flex-col py-20 items-center gap-14 text-black">
        <div className="flex flex-col gap-3 items-center">
          <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
            Dear Students
          </h1>
          <div className="w-28 bg-[#198754] h-2  rounded-full" />
        </div>
        <div className="flex  bg-[#dbdbdb] max-w-screen-xl rounded-2xl w-[40vw] mx-auto gap-10 px-2 sm:px-20 py-10">
          <div className="flex justify-center items-center w-full gap-10 flex-row flex-wrap">
            <Slider
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              draggable
              className="w-full"
              autoplay={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            >
              <Card title={"Title"} href={""} picture={FKG1} />
              <Card title={"Title"} href={""} picture={FKG2} />
              <Card title={"Title"} href={""} picture={FKG3} />
            </Slider>
          </div>
        </div>
        <div className="p-10 lg:w-[70%] leading-loose">
          <p className="text-center max-w-screen-lg text-black">
            Greetings from the
            <span className="font-bold text-[#198754]">
              {" "}
              Faculty of Dentistry Universitas Indonesia!{" "}
            </span>
            <br />
            The Faculty of Dentistry Universitas Indonesia opens the opportunity
            for dentistry students from all over the world to participate in our
            exchange program. The duration of this program depends on each
            university. Before applying, please make sure that you know every
            requirement in this program.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Students;
