import React from "react";
import "../pages/ContactUs/style.css";

const Contact = () => {
  return (
    <div className="">
      <div className="w-full py-5 px-4 my-20">
        <div className="flex flex-col gap-3 items-center mt-10">
          <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
            Our Contacts
          </h1>
          <div className="w-28 bg-[#198754] h-2  rounded-full" />
        </div>
        <div className="lg:flex justify-center m-10 leading-relaxed">
          <p className="email-international text-l px-10 py-7">
            <span className="font-semibold text-wrapper-8">
              Email
              <br />
            </span>
            <ul>
              <li>
                <span className="text-wrapper-9">
                  &#8226; International Office FKG UI
                </span>
                <br></br>
                <span className="text-wrapper-10">&nbsp;</span>
                <a
                  href="mailto:io.dent@ui.ac.id"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="text-wrapper-11 text-[#198754]">
                    io.dent@ui.ac.id
                    <br />
                  </span>
                </a>
              </li>
              <li>
                <span className="text-wrapper-9">
                  &#8226; Administrative Staff of International Affairs FKG UI{" "}
                  <br></br>
                </span>
                <a
                  href="mailto:siti.nadya03@office.ui.ac.id"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span className="text-wrapper-12 text-[#198754]">
                    siti.nadya03@office.ui.ac.id
                  </span>
                </a>
              </li>
            </ul>
          </p>
          <p className="handphone-number text-l px-10 py-7">
            <span className="font-semibold text-wrapper-13">
              Handphone Number
              <br />
            </span>
            <span className="text-wrapper-9 ">
              &#8226; Administrative Staff of International Affairs FKG UI{" "}
              <br />
              +62 813-1402-9914 <br />
              (Siti Nadya Ulfa)
            </span>
          </p>
        </div>

        <div className="flex justify-center mb-10 frame-4">
          <button
            onClick={() => (window.location = "mailto:io.dent@ui.ac.id")}
            className="font-extrabold items-start bg-[#198754] rounded-lg inline-flex flex-col h-auto relative w-auto m-auto px-10 py-3 mt-10 text-white"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
