import React from "react";
import "../pages/LecturerOutbound/style.css";
import AnneMarie from "../assets/lecturer/AnneMarie.jpg";
import JunjiTagami from "../assets/lecturer/JunjiTagami.jpg";

const AdjunctProfessor = () => {
  return (
    <div className="relations-unit">
      <div className="m-10">
        <div className="flex justify-center">
          <div className="flex flex-col gap-3 items-center">
            <h1 className="text-xl font-semibold py-2 text-[#198754] sm:text-2xl">
              Our Adjunct Professor
            </h1>
            <div className="w-28 bg-[#198754] h-2 rounded-xl" />
          </div>
        </div>
        <div className="items-center bg-[#f5f5f5] mb-20">
          <div className="mt-10 mb-17 pt-10">
            <div className="font-bold text-black text-center mt-4 mx-10 lg:flex justify-center gap-8">
              <div className="flex flex-col items-center p-5">
                <img
                  className="m-auto h-[20vh] lg:w-[10vw] w-[20vw] object-center object-cover rounded-xl"
                  alt="Professor Junji Tagami, DDS, PhD"
                  src={JunjiTagami}
                />
                <p className="mt-4 text-center flex-grow">
                  Prof. Junji Tagami,
                  <br /> DDS, PhD
                  <br />
                </p>
              </div>
              <div className="flex flex-col items-center p-5">
                <img
                  className="m-auto h-[20vh] lg:w-[10vw] w-[20vw] object-center object-cover rounded-xl"
                  alt="Prof. Anne Marie"
                  src={AnneMarie}
                />
                <p className="mt-4 text-center flex-grow">
                  Prof. Anne Marie <br />
                  Kuijpers-Jagtman, <br />
                  DDS, PhD, FDSRCS Eng
                  <br />
                </p>
              </div>
            </div>

            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjunctProfessor;
