import React from "react";

const EmergencyNumber = () => {
  return (
    <div className="">
      <div className="px-4 my-10">
        <div className="m-10 poppins">
          <div className="flex justify-start mx-10">
            <div className=" w-[65px] h-[40px] bg-[#198754]" />
            <div className="poppins text-[#198754] m-5 text-2xl font-semibold relative w-fit mt-[-1.00px]">
              Emergency Contacts
            </div>
          </div>
        </div>
        <div className="bg-[#f2f2f2] text-black p-4 rounded-md shadow-md mx-auto mt-10 gap-8 sm:p-8 md:flex md:justify-center md:items-start md:space-x-8 lg:w-10/12">
          {/* First Section */}
          <div className="mb-4 md:w-1/2 md:mb-0">
            <h2 className="text-lg font-semibold mb-2 text-[#198754]">
              Unit Pelaksana Teknis Keselamatan, Kesehatan Kerja, dan Lingkungan
              - UPT K3L UI
            </h2>
            <p className="mb-4">
              Unit Pelaksana Teknis Keselamatan, Kesehatan Kerja, dan Lingkungan
              or in English is the{" "}
              <span className="font-bold">
                {" "}
                Safety, Occupational Health and Environment (K3L) Technical
                Implementation Unit{" "}
              </span>
              is tasked with compiling and implementing Occupational Safety,
              Health and Environment (K3L) management policies, including
              drafting recommendations for rector regulations, protocols,
              guidelines, POB, K3L risk management, and fire prevention efforts
              and emergency preparedness in the University of Indonesia campus
              environment.
            </p>
            <p className="mb-4">
              Regarding the emergency contact at Universitas Indonesia, we have
              each person to be responsible for the UPT K3L assigned for each
              building. Below contacts are for the UPT K3L of the Faculty of
              Dentistry UI in Salemba Campus and Health Sciences Cluster
              Universitas Indonesia in Depok Campus:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>
                Faculty of Dentistry UI (Salemba Campus): <br></br>Ms. Wulan
                (+62) 813 1704 4778
              </li>
              <li>
                Health Sciences Cluster UI (Depok Campus): <br></br> Mr. Agus
                (+62) 857 7793 1044
              </li>
              <li>
                Health Sciences Cluster UI (Depok Campus): <br></br>Mr. Eko
                (+62) 815 8280 006
              </li>
              <li>
                UPT K3L Universitas Indonesia: <br></br>(021) 291 209 32
                <br></br>
                Email: k2l@ui.ac.id
              </li>
            </ul>
          </div>

          {/* Second Section */}
          <div className="md:w-1/2">
            <p className="mb-4">
              Also, we provided Faculty of Dentistry International Office
              numbers consisting of our phone number and email:
            </p>
            <ul className="list-disc pl-5 mb-4">
              <li>
                Staff of International Affairs Unit of the Faculty of Dentistry
                UI: <br></br>Ms. Nadya (+62) 813 1402 9914<br></br>Email:
                siti.nadya03@office.ui.ac.id
              </li>
              <li>
                International Affairs Unit of the Faculty of Dentistry UI:{" "}
                <br></br>io.dent@ui.ac.id
              </li>
            </ul>
            <h2 className="text-lg font-semibold mb-2 text-[#198754]">
              List of the Emergency Contact:
            </h2>
            <ul className="list-disc pl-5 mb-4">
              <li>Emergency Response Call Center Services: 112</li>
              <li>
                Ambulance/Medical Emergencies: 118 or 119 <br></br>Call
                (+62)2165303118 for DKI Jakarta area
              </li>
              <li>Firefighter: 113</li>
              <li>Police: 110</li>
              <li>Badan Nasional Pencarian dan Pertolongan (BASARNAS): 115</li>
              <li>Hotline Covid-19: 119</li>
              <li>Palang Merah Indonesia: (+62)7992325</li>
              <li>Tourist Police (Jakarta): (+62)215264073</li>
              <li>
                Tourist Police (Depok): (+62)2177202414 / (+62)217520529
                <br></br>Address: Jalan Margonda Raya No. 14, Depok
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmergencyNumber;
