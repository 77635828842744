import Card from "./Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Magister from "../assets/homepage/Magister.png";
import Undergraduate from "../assets/homepage/Undergraduate.png";
import Spesialis from "../assets/homepage/Spesialis.png";
import Doktor from "../assets/homepage/Doktor.png";

const OurPrograms = () => {
  return (
    <section className="flex flex-col w-full py-20 px-4 overflow-hidden sm:px-8 items-center gap-14 text-[#198754] ">
      <div className="w-full max-w-screen-xl flex items-center gap-5">
        <div className="w-20 h-10 bg-[#198754]" />
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
          Our Programs
        </h1>
      </div>
      <div className="flex  bg-[#dbdbdb] max-w-screen-xl rounded-2xl w-full mx-auto gap-10 px-2 sm:px-20 py-10">
        <div className="flex justify-center items-center w-full gap-10 flex-row flex-wrap">
          <Slider
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            draggable
            className="w-full"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <Card
              title={"Undergraduate Program (S1)"}
              href={
                "https://www.ui.ac.id/en/academic/faculty/faculty-of-dentistry/"
              }
              picture={Undergraduate}
            />
            <Card
              title={"Master's Program"}
              href={
                "https://www.ui.ac.id/en/academic/faculty/faculty-of-dentistry/"
              }
              picture={Magister}
            />
            <Card
              title={"Doctoral Program (S3)"}
              href={
                "https://www.ui.ac.id/en/academic/faculty/faculty-of-dentistry/"
              }
              picture={Doktor}
            />
            <Card
              title={"Specialist Program"}
              href={
                "https://www.ui.ac.id/en/academic/faculty/faculty-of-dentistry/"
              }
              picture={Spesialis}
            />
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default OurPrograms;
