import Card from "./../Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Ancol from "../../assets/studentExchange/places/Ancol.png";
import Dufan from "../../assets/studentExchange/places/Dufan.png";
import Fatahillah from "../../assets/studentExchange/places/Fatahillah.png";
import FineArt from "../../assets/studentExchange/places/FineArt.png";
import GrandIndo from "../../assets/studentExchange/places/GrandIndo.png";
import JakartaSky from "../../assets/studentExchange/places/JakartaSky.png";
import KebunRaya from "../../assets/studentExchange/places/KebunRaya.png";
import KotaKasa from "../../assets/studentExchange/places/KotaKasa.png";
import KotaTua from "../../assets/studentExchange/places/KotaTua.png";
import LittleJapan from "../../assets/studentExchange/places/LittleJapan.png";
import Monas from "../../assets/studentExchange/places/Monas.png";
import MuseumBI from "../../assets/studentExchange/places/MuseumBI.png";
import MuseumBM from "../../assets/studentExchange/places/MuseumBM.png";
import MuseumMacan from "../../assets/studentExchange/places/MuseumMacan.png";
import PIK from "../../assets/studentExchange/places/PIK.png";
import TMII from "../../assets/studentExchange/places/TMII.png";

import AroemJkt from "../../assets/studentExchange/places/AroemJkt.png";
import Batavia from "../../assets/studentExchange/places/Batavia.png";
import CafeAround from "../../assets/studentExchange/places/CafeAround.png";
import JurnalRisa from "../../assets/studentExchange/places/JurnalRisa.png";
import KedaiKita from "../../assets/studentExchange/places/KedaiKita.png";
import KedaiSeni from "../../assets/studentExchange/places/KedaiSeni.png";
import KopiNako from "../../assets/studentExchange/places/KopiNako.png";
import Ragusa from "../../assets/studentExchange/places/Ragusa.png";

const PlaceOfInterest = () => {
  return (
    <div className="requirements">
      <div className="mx-10 poppins p-10">
        <div className="flex justify-start">
          <div className=" w-[65px] h-[40px] bg-[#198754]" />
          <div className="poppins text-[#198754] m-5 text-2xl relative font-semibold w-fit mt-[-1.00px]">
            Place-To-Go in Jakarta
          </div>
        </div>
      </div>
      <h2 className="font-bold text-black text-left text-center mb-4">Place</h2>
      <div className="flex  bg-[#dbdbdb] max-w-screen-xl rounded-2xl w-full mx-auto gap-10 px-2 sm:px-20 py-10">
        <div className="flex justify-center items-center w-full gap-10 flex-row flex-wrap">
          <Slider
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            draggable
            className="w-full"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <Card
              title={"Monumen Nasional"}
              href={"https://www.instagram.com/monumen.nasional/ "}
              picture={Monas}
            />
            <Card
              title={"Pantai Indah Kapuk (PIK)"}
              href={"https://www.instagram.com/aloha.pik?igsh=dWwxNmZodWVibjYy"}
              picture={PIK}
            />
            <Card
              title={"Ancol"}
              href={"https://www.instagram.com/ancoltamanimpian/?hl=en"}
              picture={Ancol}
            />
            <Card
              title={"Dunia Fantasi Ancol"}
              href={"https://www.instagram.com/infodufan/?hl=en"}
              picture={Dufan}
            />
            <Card
              title={"Taman Mini Indonesia Indah"}
              href={"https://www.instagram.com/tmiiofficial/?hl=en"}
              picture={TMII}
            />
            <Card
              title={"Grand Indonesia Mall - The Biggest Mall in Jakarta"}
              href={"https://www.instagram.com/grandindo/"}
              picture={GrandIndo}
            />
            <Card
              title={"Kota Kasablanka Mall"}
              href={"https://www.instagram.com/kotakasablanka/"}
              picture={KotaKasa}
            />
            <Card
              title={"Museum Macan"}
              href={
                "https://fkg.ui.ac.id/en/department/ilmu-material-kedokteran-gigi/"
              }
              picture={MuseumMacan}
            />
            <Card
              title={"Kebun Raya Bogor"}
              href={"https://www.instagram.com/kebunraya_id/?hl=en"}
              picture={KebunRaya}
            />
            <Card
              title={"Jakarta Skyscrapers - Jakarta Free Tour Bus"}
              href={
                "https://www.instagram.com/reel/CwzvukspGVv/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
              }
              picture={JakartaSky}
            />
            <Card
              title={"Museum Kofta Tua Jakarta (Old Town)"}
              href={"https://www.instagram.com/wisatakotatua/?hl=en"}
              picture={KotaTua}
            />
            <Card
              title={"Museum Fatahillah"}
              href={
                "https://instagram.com/museumkesejarahan?igshid=NzZlODBkYWE4Ng"
              }
              picture={Fatahillah}
            />
            <Card
              title={"Museum of Fine Arts and Ceramics"}
              href={
                "https://instagram.com/museum_senijkt?igshid=NzZlODBkYWE4Ng=="
              }
              picture={FineArt}
            />
            <Card
              title={"Museum Bank Indonesia"}
              href={"https://instagram.com/museum_bi?igshid=NzZlODBkYWE4Ng=="}
              picture={MuseumBI}
            />
            <Card
              title={"Museum Bank Mandiri"}
              href={
                "https://instagram.com/museum_mandiri?igshid=NzZlODBkYWE4Ng=="
              }
              picture={MuseumBM}
            />
          </Slider>
        </div>
      </div>
      <br />
      <h2 className="font-bold text-black text-left text-center mb-4 mt-8">
        Cafe and Food
      </h2>
      <div className="flex  bg-[#dbdbdb] max-w-screen-xl rounded-2xl w-full mx-auto gap-10 px-2 sm:px-20 py-10">
        <div className="flex justify-center items-center w-full gap-10 flex-row flex-wrap">
          <Slider
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            draggable
            className="w-full"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <Card
              title={"Kedai Kita - Pizza, Hotplate"}
              href={"https://www.instagram.com/kedaikitabogor/?hl=en"}
              picture={KedaiKita}
            />
            <Card
              title={"Cafe Around Jakarta"}
              href={"https://www.instagram.com/jktcoffeehunt/?hl=en"}
              picture={CafeAround}
            />
            <Card
              title={"Kopi Nako Monas - Cafe"}
              href={"https://www.instagram.com/kopinako.monas/"}
              picture={KopiNako}
            />
            <Card
              title={"Aroem Jakarta - Indonesian Food"}
              href={"https://www.instagram.com/aroem.jakarta/"}
              picture={AroemJkt}
            />
            <Card
              title={"Jurnal Risa Cafe Jakarta"}
              href={"https://www.instagram.com/jurnalrisacoffee.jkt/"}
              picture={JurnalRisa}
            />
            <Card
              title={"Kedai Seni Djakarta"}
              href={
                "https://instagram.com/kedaisenidjakarte?igshid=NzZlODBkYWE4Ng=="
              }
              picture={KedaiSeni}
            />
            <Card
              title={"Cafe Batavia"}
              href={
                "https://instagram.com/cafe_batavia?igshid=NzZlODBkYWE4Ng=="
              }
              picture={Batavia}
            />
            <Card
              title={"Ragusa Es Italia"}
              href={"https://www.instagram.com/ragusa.jkt/?hl=en"}
              picture={Ragusa}
            />
            <Card
              title={"Little Japan - Restaurant"}
              href={"https://www.instagram.com/littlejapan.id/?hl=en"}
              picture={LittleJapan}
            />
          </Slider>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default PlaceOfInterest;
