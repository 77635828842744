import React, { useState } from "react";
import "tailwindcss/tailwind.css";

import Default from "../assets/activities/default.png";
import APAN from "../assets/activities/APAN53.jpeg";
import APAN54 from "../assets/activities/APAN54.png";
import EMGHCC from "../assets/activities/EMGHCC.jpg";
import VKU from "../assets/activities/VKU.jpeg";
import UKMDENSTA from "../assets/activities/UKMDENSTA.jpeg";
import InterSouth from "../assets/activities/InterSouth.png";
import FDCU from "../assets/activities/FDCU.jpeg";
import WCSS from "../assets/activities/WCSS.PNG";
import CSMU11 from "../assets/activities/11thCSMU.jpeg";
import RadboudCongress from "../assets/activities/RadboudCongress.JPG";
import Summerschool from "../assets/activities/Summerschool.jpg";
import AsianPeriodontology from "../assets/activities/14thAsianPeriodontology.jpg";
import InternationalIral from "../assets/activities/InternationalIral.jpg";
import SEPudhi from "../assets/activities/SE-Pudhisastra.jpg";
import VEP2022MU from "../assets/activities/VEP2022UM.JPG";
import ChinaASEAN8th from "../assets/activities/ChinaASEAN8th.JPG";
import UITUJoint from "../assets/activities/UI-TUJoint.png";
import CAandiND from "../assets/activities/CAandiND.jpg";
import AUNQA19th from "../assets/activities/AUNQA19th.jpg";
import AUAYF2023 from "../assets/activities/AUAYF2023.jpeg";
import APAN55 from "../assets/activities/APAN55.jpg";
import InternationalRes from "../assets/activities/InternationalRes.jpeg";
import IntJoint from "../assets/activities/IntJoint.jpg";
import AUNQAPA from "../assets/activities/AUNQAPA.jpg";
import StudExTham from "../assets/activities/StudExTham.jpeg";
import StudExKago from "../assets/activities/StudExKago.jpeg";
import HokkaidoVisit from "../assets/activities/HokkaidoVisit.JPG";
import Inter17th from "../assets/activities/Inter17th.jpeg";
import SEAADE from "../assets/activities/SEAADE.jpeg";
import AnnChina from "../assets/activities/AnnChina.jpeg";

const ACTIVITIES_DUMMY = [
  {
    activity: "The 53rd APAN TELE-MEDICINE",
    date: "09/03/2022",
    status: "Done",
    description: "",
    action: false,
    photoUrl: APAN,
    activityShort: "",
  },
  {
    activity: "FDCU 2022",
    date: "18/05/2022",
    status: "Done",
    description: "",
    action: true,
    activityShort: "",
    photoUrl: FDCU,
  },
  {
    activity: "EMGHCC 2022",
    date: "19/03/2022",
    status: "Done",
    description: "",
    action: false,
    activityShort: "",
    photoUrl: EMGHCC,
  },
  {
    activity: "2022 WCSS UIP and Campus Asia+",
    date: "27/06/2022",
    status: "Done",
    activityShort: "",
    description: "",
    action: false,
    photoUrl: WCSS,
  },
  {
    activity: "UKMDENSA 2022",
    date: "16/04/2022",
    activityShort: "",
    status: "Done",
    description: "",
    action: true,
    photoUrl: UKMDENSTA,
  },
  {
    activity:
      "14th International Congress of Cleft Lip, Palate & Related Craniofacial Anomalies at Edinburgh, Scotland, United Kingdom",
    date: "13/07/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: RadboudCongress,
    activityShort:
      "14th International Congress of Cleft Lip, Palate & Related Craniofacial Anomalies",
  },
  {
    activity: "[VKU] Neurophatic Orofacial Pain",
    date: "18/04/2022",
    status: "Done",
    description: "",
    action: true,
    activityShort: "",
    photoUrl: VKU,
  },
  {
    activity: "The 11th CSMU Ortho Regular",
    date: "27/07/2022",
    status: "Done",
    description: "",
    activityShort: "",
    action: true,
    photoUrl: CSMU11,
  },
  {
    activity: "International Oral Medicine Conference CSMU 2022",
    date: "28/04/2022",
    status: "Done",
    description: "",
    action: false,
    activityShort: "",
    photoUrl: InterSouth,
  },
  {
    activity: "Summerschool Heidelberg 2022",
    date: "01/08/2022",
    status: "Done",
    description: "",
    action: false,
    activityShort: "",
    photoUrl: Summerschool,
  },

  // Second Page
  {
    activity: "The 54th APAN",
    date: "24/08/2022",
    status: "Done",
    description: "",
    action: false,
    activityShort: "",
    photoUrl: APAN54,
  },
  {
    activity: "The 8th China ASEAN Forum on Dentistry",
    date: "10/12/2022",
    status: "Done",
    description: "",
    action: false,
    photoUrl: ChinaASEAN8th,
    activityShort: "",
  },
  {
    activity: "The 14th Asian Pacific Society of Periodontology",
    date: "29/08/2022",
    status: "Done",
    description: "",
    action: true,
    photoUrl: AsianPeriodontology,
    activityShort: "",
  },
  {
    activity: "UI-TU International Joint Symposium",
    date: "15/12/2022",
    status: "Done",
    description: "",
    action: false,
    activityShort: "",
    photoUrl: UITUJoint,
  },
  {
    activity: "CSMU International Medicine Workshop 2022",
    date: "23/09/2022",
    status: "Done",
    description: "",
    action: true,
    photoUrl: InternationalIral,
    activityShort: "",
  },
  {
    activity: "CA and inD International Symposium 2023",
    date: "28/01/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: CAandiND,
    activityShort: "",
  },
  {
    activity: "Student Exchange - Puthisastra University",
    date: "28/10/2022",
    status: "Done",
    description: "",
    action: true,
    photoUrl: SEPudhi,
    activityShort: "",
  },
  {
    activity: "The 319th AUN-QA Programme Assesment",
    date: "27/02/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: AUNQA19th,
    activityShort: "",
  },
  {
    activity: "Virtual Exchange Program 2022 Malaya Universiti",
    date: "03/12/2022",
    status: "Done",
    description: "",
    action: true,
    photoUrl: VEP2022MU,
    activityShort: "",
  },
  {
    activity: "Asian Universities Alliance Youth Forum 2023",
    date: "12/03/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: AUAYF2023,
    activityShort: "",
  },

  // Third Page
  {
    activity: "The 55th APAN",
    date: "14/03/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: APAN55,
    activityShort: "",
  },
  {
    activity: "StudEx Kagoshima University",
    date: "09/08/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: StudExKago,
    activityShort: "",
  },
  {
    activity: "International Research Internship Seoul National University",
    date: "31/05/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: InternationalRes,
    activityShort: "",
  },
  {
    activity: "Hokkaido University Visit",
    date: "15/09/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: HokkaidoVisit,
    activityShort: "",
  },
  {
    activity: "International Joint Publication and Research Mobility",
    date: "05/06/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: IntJoint,
    activityShort: "",
  },
  {
    activity:
      "The 17th International Dental Collaboration of The Mekong River Region (IDCMR)",
    date: "12/10/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: Inter17th,
    activityShort: "",
  },
  {
    activity: "The 334th AUN-QA Programme Assessment ",
    date: "27/06/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: AUNQAPA,
    activityShort: "",
  },
  {
    activity: "34th Annual SEAADE 2023",
    date: "20/11/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: SEAADE,
    activityShort: "",
  },
  {
    activity: "StudEx Thammasat University",
    date: "02/07/2023",
    status: "Done",
    description: "",
    action: false,
    photoUrl: StudExTham,
    activityShort: "",
  },
  {
    activity:
      "The 2023 Annual Meeting of China - ASEAN Expert Advisory Commitee on Dentistry",
    date: "26/12/2023",
    status: "Done",
    description: "",
    action: true,
    photoUrl: AnnChina,
    activityShort: "",
  },
];

const ActivityItem = ({ activity, onSelect }) => {
  return (
    <div
      className="p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-100 transition duration-150 border"
      onClick={() => onSelect(activity)}
    >
      <h3 className="text-lg font-semibold">
        {activity.activityShort === ""
          ? activity.activity
          : activity.activityShort}
      </h3>
      <p className="text-sm text-gray-600">Date: {activity.date}</p>
      <p
        className={`text-sm ${
          activity.status === "Done"
            ? "text-green-500"
            : activity.status === "Done"
            ? "text-red-500"
            : "text-blue-500"
        }`}
      >
        Status: {activity.status}
      </p>
    </div>
  );
};

const ActivityDetailModal = ({ activity, onClose }) => {
  if (!activity) return null;

  // Assuming 'photoUrl' is the property for the image. Replace with actual field from your data.
  const photoUrl = activity.photoUrl || Default;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 overflow-y-auto h-full w-full flex items-center justify-center p-4">
      <div className="relative mx-auto bg-white rounded-lg shadow-xl max-w-4xl w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-2xl font-bold text-gray-800">
            {activity.activity}
          </h2>
          <button
            className="text-gray-800 text-lg font-semibold hover:text-gray-600"
            onClick={onClose}
          >
            ×
          </button>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 p-16">
            <img
              src={photoUrl}
              alt={activity.activity}
              className="rounded-lg shadow-md h-auto"
            />
          </div>
          <div className="md:w-1/2 py-16">
            <p className="text-lg text-gray-700 mb-2">
              Date: <span className="font-semibold">{activity.date}</span>
            </p>
            <p className="text-lg text-gray-700 mb-2">
              Status:{" "}
              <span
                className={`font-semibold ${
                  activity.status === "Done"
                    ? "text-green-500"
                    : activity.status === "Done"
                    ? "text-red-500"
                    : "text-blue-500"
                }`}
              >
                {activity.status}
              </span>
            </p>
            {/* <p className="text-lg text-gray-700 mb-2">Action Required: <span className="font-semibold">{activity.action ? 'Yes' : 'No'}</span></p> */}
            {/* Add any other details you wish to include */}
          </div>
        </div>
      </div>
    </div>
  );
};

const ActivitiesList = () => {
  const [currentActivities, setCurrentActivities] = useState(
    ACTIVITIES_DUMMY.slice(0, 10)
  );
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleSelectActivity = (activity) => {
    setSelectedActivity(activity);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePagination = (direction) => {
    let newIndex = pageIndex + direction;
    if (newIndex < 0) newIndex = 2;
    if (newIndex > 2) newIndex = 0;
    setCurrentActivities(
      ACTIVITIES_DUMMY.slice(newIndex * 10, (newIndex + 1) * 10)
    );
    setPageIndex(newIndex);
  };

  return (
    <section className="flex flex-col py-20 items-center gap-14">
      <div className="w-full max-w-screen-xl flex items-center gap-5">
        <div className="w-20 h-10 bg-[#198754]" />
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
          Our Activities
        </h1>
      </div>
      <div className="bg-[#f2f2f2] text-black px-8 rounded-md shadow-md w-full md:w-10/12 mx-auto hidden xl:block">
        <div className="flex flex-col md:flex-row justify-around items-center gap-8 md:items-start py-10">
          <div className="container mx-auto px-8 py-8 bg-white">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 ">
              {currentActivities.slice(0, 4).map((activity, index) => (
                <ActivityItem
                  key={index}
                  activity={activity}
                  onSelect={handleSelectActivity}
                />
              ))}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
              {currentActivities.slice(4, 10).map((activity, index) => (
                <ActivityItem
                  key={index + 5}
                  activity={activity}
                  onSelect={handleSelectActivity}
                />
              ))}
            </div>
            <div className="flex justify-center space-x-4 mt-8">
              <div className="w-32">
                <button
                  className="w-full px-4 py-2 border bg-[#198754] text-white rounded hover:bg-[#198770] transition duration-150"
                  onClick={() => handlePagination(-1)}
                >
                  Previous
                </button>
              </div>
              <div className="w-32">
                <button
                  className="w-full px-4 py-2 border bg-[#198754] text-white rounded hover:bg-[#198770] transition duration-150"
                  onClick={() => handlePagination(1)}
                >
                  Next
                </button>
              </div>
            </div>
            {showModal && (
              <ActivityDetailModal
                activity={selectedActivity}
                onClose={handleCloseModal}
              />
            )}
          </div>
        </div>
      </div>
      <div className="container mx-auto p-4 xl:hidden">
        <div className="grid xl:hidden xl:grid-cols-2 gap-4 mb-4">
          {currentActivities.slice(0, 5).map((activity, index) => (
            <ActivityItem
              key={index}
              activity={activity}
              onSelect={handleSelectActivity}
            />
          ))}
        </div>
        <div className="grid xl:hidden xl:grid-cols-2 gap-4">
          {currentActivities.slice(5, 10).map((activity, index) => (
            <ActivityItem
              key={index + 5}
              activity={activity}
              onSelect={handleSelectActivity}
            />
          ))}
        </div>
        <div className="flex justify-center space-x-4 mt-8">
          <div className="w-32">
            <button
              className="w-full px-4 py-2 border bg-[#198754] text-white rounded hover:bg-[#198770] transition duration-150"
              onClick={() => handlePagination(-1)}
            >
              Previous
            </button>
          </div>
          <div className="w-32">
            <button
              className="w-full px-4 py-2 border bg-[#198754] text-white rounded hover:bg-[#198770] transition duration-150"
              onClick={() => handlePagination(1)}
            >
              Next
            </button>
          </div>
        </div>
        {showModal && (
          <ActivityDetailModal
            activity={selectedActivity}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </section>
  );
};

export default ActivitiesList;
