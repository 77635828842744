import React from "react";

const Arrow = () => (
  <svg width="14" height="8" viewBox="0 0 16 14">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.293 0.299012C8.48053 0.111541 8.73484 0.00622559 9 0.00622559C9.26516 0.00622559 9.51947 0.111541 9.707 0.299012L15.707 6.29901C15.8945 6.48654 15.9998 6.74085 15.9998 7.00601C15.9998 7.27118 15.8945 7.52548 15.707 7.71301L9.707 13.713C9.5184 13.8952 9.2658 13.996 9.0036 13.9937C8.7414 13.9914 8.49059 13.8862 8.30518 13.7008C8.11977 13.5154 8.0146 13.2646 8.01233 13.0024C8.01005 12.7402 8.11084 12.4876 8.293 12.299L12.586 8.00601H1C0.734784 8.00601 0.48043 7.90065 0.292893 7.71312C0.105357 7.52558 0 7.27123 0 7.00601C0 6.7408 0.105357 6.48644 0.292893 6.29891C0.48043 6.11137 0.734784 6.00601 1 6.00601H12.586L8.293 1.71301C8.10553 1.52548 8.00021 1.27118 8.00021 1.00601C8.00021 0.740848 8.10553 0.48654 8.293 0.299012Z"
      fill="#198754"
    />
  </svg>
);

const Card = ({ title, href, picture }) => (
  <div className="relative flex w-full md:max-w-xs flex-col gap-2">
    <img
      src={picture}
      className="aspect-video w-full bg-[#D9D9D9] rounded-2xl"
      alt="Programs"
    />
    <h2 className="font-medium text-lg text-[#198754]">{title}</h2>{" "}
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="text-[#198754] text-xs font-semibold flex items-center gap-2"
    >
      <span>SEE MORE</span> <Arrow />
    </a>
  </div>
);

export default Card;
