import React from "react";

const Cultural = () => {
  return (
    <div className="cultural ">
      <section className="flex flex-col py-20 items-center gap-14 text-black">
        <div className="flex flex-col gap-3 items-center">
          <h1 className="md:text-3xl sm:text-4xl text-2xl font-semibold py-2 text-[#198754]">
            Cultural Programs
          </h1>
          <div className="w-28 bg-[#198754] h-2 rounded-full" />
        </div>
        <div className="w-1/3 aspect-w-4 aspect-h-1">
          <iframe
            className="w-full h-full"
            src="https://drive.google.com/file/d/1mtVGIiARjjoD75eel6vA-UYqF1h_1iaW/preview"
            title="Google Drive Video"
            allowFullScreen
          ></iframe>
        </div>
          <div className="mt-4 font-normal text-black leading-loose px-10 lg:px-5 lg:w-[60%] mx-auto">
              <p className="text-black text-center max-w-screen-lg px-4">
                  <span className="text-black">See more on our Instagram </span>
              </p>
              <p className="text-black text-center max-w-screen-lg px-4">
              <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://instagram.com/swm_ui?igshid=NzZlODBkYWE4Ng=="
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Sekar Widya Makara UI{" "}
                      </span>
                      |
                </a>
              <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://instagram.com/kridabudaya?igshid=NzZlODBkYWE4Ng=="
                    >
                      <span className="font-bold text-black">
                        {" "}
                        Krida Budaya UI{" "}
                      </span>
                      |
                </a>
                <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://instagram.com/tristiquefkgui?igshid=NzZlODBkYWE4Ng=="
                    >
                      <span className="font-bold text-black">
                        {" "}
                        TRISTIQUE FKG UI{" "}
                      </span>
                </a>
                </p>
          </div>
        <div className="w-fit flex justify-between h-fit relative "></div>
      </section>
    </div>
  );
};

export default Cultural;
