import AboutFKGImgSrc from "../assets/homepage/aboutFKG.png";

const AboutFKG = () => {
  return (
    <section className="flex flex-col py-20 w-full overflow-hidden items-center gap-14 text-black">
      <div className="flex flex-col gap-3 items-center">
        <h1 className="md:text-2xl sm:text-3xl text-2xl text-center font-semibold py-2 text-[#198754]">
          About FKG Universitas Indonesia
        </h1>
        <div className="w-28 bg-[#198754] h-2  rounded-full" />
      </div>
      <div className="w-full px-6 max-w-screen-lg overflow-x-scroll md:overflow-visible">
        <div className="w-fit mx-auto md:w-full md:max-w-screen-sm xl:max-w-none gap-4  flex justify-center h-fit relative">
          <div className="aspect-video w-[15rem] sm:w-[30rem] md:w-[20rem] xl:w-[30rem] relative z-[1]">
            <img
              src={AboutFKGImgSrc}
              alt="About FKG"
              className="w-full object-contain"
            />
          </div>
        </div>
      </div>
      <div className="mt-5">
        <p className="text-center max-w-screen-lg px-4">
          In line with the vision of realizing a superior Faculty of Dentistry,
          the Faculty of Dentistry, Universitas Indonesia bears a great
          responsibility to become a leading dental institution in Indonesia and
          has a global outlook. Our trusted supports are our highly qualified
          teaching staff, who are highly dedicated and committed to providing
          the best education for the nation's best experts.
        </p>
        <br />
        <br />
        <p className="text-black text-center max-w-screen-lg px-4">
          <span className="font-bold text-[#198754]">
            Vision of the Faculty of Dentistry
          </span>
        </p>
        <p className="text-black text-center max-w-screen-lg px-4">
          “Creating a leading Faculty of Dentistry that becomes the flagship of
          Southeast Asia.”
        </p>
        <br />
        <br />
        <p className="text-black text-center max-w-screen-lg px-4">
          <span className="font-bold text-[#198754]">
            Mission of the Faculty of Dentistry, University of Indonesia{" "}
          </span>
        </p>
        <div class="text-black text-left max-w-screen-lg px-4">
          <ul class="list-outside list-disc  pl-5">
            <li class="ml-0">
              To provide quality dental education and teaching.
            </li>
            <li class="ml-0">
              To conduct Three Pillars of Higher Education activities with high
              quality and relevant to national and global dental and health
              issues.
            </li>
            <li class="ml-0">
              To produce graduates who are superior, ethical and professional,
              and able to compete globally.
            </li>
            <li class="ml-0">
              To create an academic climate that is able to realize the vision
              of FKGUI (“Faculty of Dentistry, Universitas Indonesia”).
            </li>
            <li class="ml-0">
              To conduct quality dental and oral health services as a center of
              reference.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutFKG;
