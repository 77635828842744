import AboutFKGImgSrc from "../assets/homepage/aboutFKG.png";
import Dark from "../assets/homepage/whyui-dark.png";
import Profile from "../assets/homepage/profile.png";
import { useState } from "react";

import JunjiTagami from "../assets/lecturer/2022/JunjiTagami.png";
import MelbVisit from "../assets/lecturer/2022/MelbVisit.png";
import Tohoku from "../assets/lecturer/2022/Tohoku.png";
import KingSalman from "../assets/lecturer/2022/KingSalman.png";

import Chulangkorn from "../assets/lecturer/2023/Chulangkorn.png";
import BMedSciMelb from "../assets/lecturer/2023/BMedSciMelb.png";
import HajimeUtsono from "../assets/lecturer/2023/HajimeUtsono.png";
import OSE from "../assets/lecturer/2023/OSE.png";
import Masatsugu from "../assets/lecturer/2023/Masatsugu.png";
import Hokkaido from "../assets/lecturer/2023/Hokkaido.png";
import Newcastle from "../assets/lecturer/2023/Newcastle.png";
import SigningPennyslavia from "../assets/lecturer/2023/SigningPennyslavia.png";
import SigningCeremonyCH from "../assets/lecturer/2023/SigningCeremonyCH.png";
import SinghSinh from "../assets/lecturer/2023/SinghSinh.png";
import Teekayu from "../assets/lecturer/2023/Teekayu.png";
import Thammasat from "../assets/lecturer/2023/Thammasat.png";
import UKMalaysia from "../assets/lecturer/2023/UKMalaysia.png";

const LECTURE_DUMMY = [
  {
    id: 1,
    name: "Tohoku University Graduate School of Dentistry Visit",
    picture: Tohoku,
    date: "3/11/2022",
    testimony: "",
    rating: 5,
  },
  {
    id: 2,
    name: "King Salman International University Visit",
    picture: KingSalman,
    testimony: ``,
    rating: 5,
    date: "4/7/2022",
  },
  {
    id: 3,
    name: "General Lecture Professor Junji Tagami",
    picture: JunjiTagami,
    testimony: "",
    rating: 5,
    date: "10/11/2022",
  },
  {
    id: 4,
    name: "Melbourne University Visit",
    picture: MelbVisit,
    testimony: ``,
    rating: 5,
    date: "16/11/2022",
  },
  {
    id: 6,
    name: "Thammasat University Student Exchange and Visit",
    picture: Thammasat,
    testimony: ``,
    rating: 5,
    date: "02/07/2023 - 08/07/2023",
  },
  {
    id: 7,
    name: "Signing Ceremony Special Care Dentistry Indonesia CSMU-UI",
    picture: SigningCeremonyCH,
    testimony: ``,
    rating: 5,
    date: "13/07/2023 - 15/07/2023",
  },
  {
    id: 8,
    name: "General Lecture Professor Masatsugu Hashimoto (Tokyo Dental College)",
    picture: Masatsugu,
    testimony: ``,
    rating: 5,
    date: "07/05/2023",
  },
  {
    id: 9,
    name: "Hokkaido University Visit",
    picture: Hokkaido,
    testimony: ``,
    rating: 5,
    date: "15/09/2023",
  },
  {
    id: 10,
    name: "Universiti Kebangsaan Malaysia Visit",
    picture: UKMalaysia,
    testimony: ``,
    rating: 5,
    date: "20/03/2023",
  },
  {
    id: 11,
    name: "General Lecture Professor Amanish Singh Shinh (Adesh Institute of Medical Sciences and Research)",
    picture: SinghSinh,
    testimony: ``,
    rating: 5,
    date: "26/06/2023",
  },
  {
    id: 12,
    name: "Signing Ceremony University of Pennsylvania",
    picture: SigningPennyslavia,
    testimony: ``,
    rating: 5,
    date: "20/08/2023",
  },
  {
    id: 13,
    name: "General Lecture Professor Teekayu Plangkoorn Jorns (Khon Kaen University)",
    picture: Teekayu,
    testimony: ``,
    rating: 5,
    date: "27/09/2023",
  },
  {
    id: 14,
    name: "OSE 2.0",
    picture: OSE,
    testimony: ``,
    rating: 5,
    date: "28/11/2023",
  },
  {
    id: 15,
    name: "Chulalongkorn University Visit and Signing Ceremony",
    picture: Chulangkorn,
    testimony: ``,
    rating: 5,
    date: "30/03/2023",
  },
  {
    id: 16,
    name: "General Lecture Professor Hajime Utsuno",
    picture: HajimeUtsono,
    testimony: ``,
    rating: 5,
    date: "03/11/2023",
  },
  {
    id: 17,
    name: "Presenting MRes Newcastle University",
    picture: Newcastle,
    testimony: ``,
    rating: 5,
    date: "14/11/2023",
  },
  {
    id: 18,
    name: "Presenting BMedSci Honours Melbourne University",
    picture: BMedSciMelb,
    testimony: ``,
    rating: 5,
    date: "17/11/2023",
  },
];

const Star = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19">
      <path
        d="M9.96472 14.8835L15.9371 18.4882L14.3522 11.6943L19.6288 7.12325L12.6803 6.53374L9.96472 0.126465L7.24912 6.53374L0.300659 7.12325L5.57724 11.6943L3.99233 18.4882L9.96472 14.8835Z"
        fill="#EDD146"
      />
    </svg>
  );
};

const TestimonyCard = ({ name, picture, testimony, date, rating, id }) => {
  return (
    <div
      key={id}
      className="bg-white flex gap-4 flex-col max-w-md lg:max-w-sm w-full h-full px-5 py-6 mx-10 my-5 rounded-lg  border-2 border-[#BEBCBD]"
    >
      <div className="flex justify-between">
        <div className="w-16 aspect-square overflow-hidden">
          <img
            src={picture}
            className="w-full aspect-square object-cover"
            alt={name}
          />
        </div>
        <div className="flex">
          {Array.from({ length: rating }, (_, index) => (
            <Star key={index} />
          ))}
        </div>
      </div>
      <span className="text-[#3C4242] text-xl">{name}</span>
      <span className="text-[#807D7E] text-sm">{date}</span>
    </div>
  );
};

const LecturerMobility = () => {
  const [lectures, setLectures] = useState(LECTURE_DUMMY);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimony, setSelectedTestimony] = useState(null);

  const openModal = (testimony) => {
    setSelectedTestimony(testimony);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="flex flex-col py-20 items-center text-black">
      <div className="flex flex-col gap-3 items-center">
        <h1 className="md:text-2xl sm:text-2xl text-2xl text-center font-semibold py-2 text-[#198754]">
          Lectures Mobility
        </h1>
        <div className="w-28 bg-[#198754] h-2  rounded-full mb-16" />
      </div>
      <div className="flex flex-wrap justify-center gap-8 items-stretch">
        {lectures.map((lecture, index) => (
          <div
            key={index}
            className="flex w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 2xl:w-1/9 px-2 mb-8"
            onClick={() => openModal(lecture)}
          >
            <div className="flex flex-col w-full h-full">
              <div className="w-full flex-shrink-0 h-32 overflow-hidden">
                <img
                  className="w-full h-full object-cover object-center"
                  src={lecture.picture}
                  alt="Profile"
                />
              </div>
              <div className="flex-grow p-4 bg-white border-2 border-[#BEBCBD] rounded-b-lg shadow-lg">
                <h3 className="text-lg font-normal">{lecture.name}</h3>
                <p className="text-gray-600 mt-2">{lecture.date}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <TestimonyModal
        testimony={selectedTestimony}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </section>
  );
};

const TestimonyModal = ({ testimony, isOpen, onClose }) => {
  if (!isOpen || !testimony) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            {testimony.name}
          </h3>
          <img
            src={testimony.picture}
            alt={`${testimony.name}'s profile`}
            className="mx-auto mt-4 h-24 w-24 rounded-full object-cover"
          />
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500 text-justify">
              {testimony.testimony}
            </p>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-[#198754] text-white text-base font-medium rounded-md w-2/3 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LecturerMobility;
