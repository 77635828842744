import React from "react";
// import "./index.css";
import { Link } from "react-router-dom";

import Students from "../../components/StudentExchange/Students";
import Requirements from "../../components/StudentExchange/Requirements";
import Cultural from "../../components/StudentExchange/Cultural";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Contact from "../../components/Contact";
import Visa from "../../components/Visa";
import PlaceOfInterest from "../../components/StudentExchange/PlaceOfInterest";

let StudentExchange = () => {
  return (
    <div className="student-exchange">
      <Navbar />
      <Students />
      <Requirements />
      <Cultural />
      <PlaceOfInterest />
      <Visa />
      <Footer />
    </div>
  );
};

export default StudentExchange;
