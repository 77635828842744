import React from "react";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import Contact from "../../components/Contact";
import Visa from "../../components/Visa";
import RelationsUnit from "../../components/RelationsUnit";
import LecturerMobility from "../../components/LecturerMobility";

let Lecturer = () => {
  return (
    <div className="lecturer">
      <Navbar />
      <LecturerMobility />
      <Footer />
    </div>
  );
};

export default Lecturer;
