import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import TravelTips from "../../components/TipsAndTricks/TravelTips";
import FnB from "../../components/TipsAndTricks/FnB";
import Accomodation from "../../components/TipsAndTricks/Accomodation";
import EmergencyNumber from "../../components/TipsAndTricks/EmergencyNumber";
import MoneyChangerComp from "../../components/TipsAndTricks/MoneyChanger";

let TipsAndTricks = () => {
  return (
    <div className="student-exchange">
      <Navbar />
      <TravelTips />
      <Accomodation />
      <FnB />
      <EmergencyNumber />
      <MoneyChangerComp />
      <Footer />
    </div>
  );
};

export default TipsAndTricks;
