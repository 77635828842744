import AboutFKGImgSrc from "../assets/homepage/aboutFKG.png";
import Dark from "../assets/homepage/whyui-dark.png";
import Profile from "../assets/homepage/profile.png";
import SangdorMak from "../assets/homepage/SangdorMak.jpg";
import SallyMeng from "../assets/homepage/MengSally.jpg";
import Yui from "../assets/homepage/YuiKaja.jpg";
import { useState } from "react";

const TESTIMONIES_DUMMY = [
  {
    id: 1,
    name: "Sangdor Mak",
    picture: SangdorMak,
    testimony: `I am Sangdor Mak. Currently I am a year 6 dental student at University of Puthisastra, Cambodia. Last year, in October me and my other 5 friends had a great opportunity to go on an exchange program in Universitas Indonesia. I am impressed with a warm welcome from the university from the first day of our arrival at the airport until the departure back to Cambodia. During our exchange, we have had a variety of experiences such as clinical observations, city tour, cultural activities and especially got a chance to join the community outreach to Kampung Ilmu as an observer. Moreover, we also had a chance to meet and talk with the Dean of the faculty of Dentistry at UI. Last but not least, we would appreciate a lot for the staff and students who had accompanied us and took us to try many Indonesian cuisines. Thanks a lot for your generosity and great hospitality! Hope to see you again some day!
`,
    rating: 5,
  },
  {
    id: 2,
    name: "Meng Sally",
    picture: SallyMeng,
    testimony: `Greetings everyone. My name is Meng Sally. I am a year 6 student from University of Puthisastra, Cambodia. Almost a year ago, when I was on vacation break in year 5, I got a chance to visit one of the most beautiful countries I’ve ever been to. Although my time there was short, I fell in love with the culture in Indonesia and felt extremely grateful for the opportunity to be an exchange student in UI. It was a worthwhile time being there. Great experience to be able to see how the students there study and work. I would like to recommend every dentistry student out there who is working hard to pursue their dentistry dream to set some time aside to visit Universitas of Indonesia. Nevertheless, I hope I will get a chance in the future to visit this great university and country once again. Thank you for the warm welcome that I've received.`,
    rating: 5,
  },
  {
    id: 3,
    name: "Yui Kajayama",
    picture: Yui,
    testimony: `
    I had a very valuable experience in UI. I was able to observe many treatments in three hospitals and interact with local students and graduate students. I also experienced Indonesian culture such as Indonesian Independence Day. Furthermore, I had a chance to talk with Professor Nakamura, which made me think about dentistry and my current situation.
    
    Through this program, I learned about the current state of dentistry in Indonesia. The local students are hardworking and very kind, and I am very grateful for their warm welcome.
    
    Interacting with people from outside my country who are aiming for the same job was an opportunity to open up my world.
    
    I would like to express my sincere gratitude to Professor Nakamura, Dr. Sendy, and Nadya for their support during this program.`,
    rating: 5,
  },
];

const Star = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19">
      <path
        d="M9.96472 14.8835L15.9371 18.4882L14.3522 11.6943L19.6288 7.12325L12.6803 6.53374L9.96472 0.126465L7.24912 6.53374L0.300659 7.12325L5.57724 11.6943L3.99233 18.4882L9.96472 14.8835Z"
        fill="#EDD146"
      />
    </svg>
  );
};

const TestimonyCard = ({ name, picture, testimony, rating, id }) => {
  return (
    <div
      key={id}
      className="bg-white flex gap-4 flex-col max-w-md lg:max-w-sm w-full h-full px-5 py-6 mx-10 my-5 rounded-lg  border-2 border-[#BEBCBD]"
    >
      <div className="flex justify-between">
        <div className="w-16 aspect-square overflow-hidden">
          <img
            src={picture}
            className="w-full aspect-square object-cover"
            alt={name}
          />
        </div>
        <div className="flex">
          {Array.from({ length: rating }, (_, index) => (
            <Star key={index} />
          ))}
        </div>
      </div>
      <span className="text-[#3C4242] text-xl">{name}</span>
      <span className="text-[#807D7E] text-sm">{testimony}</span>
    </div>
  );
};

const OurTestimonies = () => {
  const [testimonies, setTestimonies] = useState(TESTIMONIES_DUMMY);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTestimony, setSelectedTestimony] = useState(null);

  const openModal = (testimony) => {
    setSelectedTestimony(testimony);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="flex flex-col py-20 items-center text-black">
      <div className="w-full max-w-screen-xl flex items-center gap-5">
        <div className="w-20 h-10 bg-[#198754]" />
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
          Stories
        </h1>
      </div>
      <div className="flex flex-col gap-3 items-center">
        <h1 className="md:text-lg sm:text-lg text-lg font-semibold py-8 text-black">
          Message from the Students
        </h1>
      </div>
      <div className="flex w-2/3 max-w-screen-xl h-fit px-2 justify-center flex-col gap-8 lg:flex-row items-center">
        <div
          className="max-w-sm mx-auto overflow-hidden shadow-lg text-left bg-white rounded-lg border-2 border-[#BEBCBD] cursor-pointer hover:shadow-xl transition-shadow duration-300"
          onClick={() => openModal(testimonies[0])}
        >
          <div className="relative w-full h-32 overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover object-center"
              src={testimonies[0].picture}
              alt="Profile"
            />
          </div>
          <div className="p-4">
            <h3 className="text-lg font-normal">
              Impressed with a warm welcome from the University
            </h3>
            <p className="text-gray-600 mt-2">Sangdor Mak</p>
            <p className="text-gray-500 text-sm mt-2">
              University of Puthisastra | Cambodia
            </p>
          </div>
        </div>

        <div
          className="max-w-sm mx-auto overflow-hidden shadow-lg text-left bg-white rounded-lg border-2 border-[#BEBCBD] cursor-pointer hover:shadow-xl transition-shadow duration-300"
          onClick={() => openModal(testimonies[2])}
        >
          <div className="relative w-full h-32 overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover object-center"
              src={testimonies[2].picture}
              alt="Profile"
            />
          </div>
          <div className="p-4">
            <h3 className="text-lg font-normal">
              Valuable experience in Universitas Indonesia
            </h3>
            <p className="text-gray-600 mt-2">Yui Kajayama</p>
            <p className="text-gray-500 text-sm mt-2">
              Kagoshima University, Japan
            </p>
          </div>
        </div>

        <div
          className="max-w-sm mx-auto overflow-hidden shadow-lg text-left bg-white rounded-lg border-2 border-[#BEBCBD] cursor-pointer hover:shadow-xl transition-shadow duration-300"
          onClick={() => openModal(testimonies[1])}
        >
          <div className="relative w-full h-32 overflow-hidden">
            <img
              className="absolute top-0 left-0 w-full h-full object-cover object-center"
              src={testimonies[1].picture}
              alt="Profile"
            />
          </div>
          <div className="p-4">
            <h3 className="text-lg font-normal">
              Great experience to see how the students there study and work
            </h3>
            <p className="text-gray-600 mt-2">Sally Meng</p>
            <p className="text-gray-500 text-sm mt-2">
              University of Puthisastra | Cambodia
            </p>
          </div>
        </div>
      </div>

      <TestimonyModal
        testimony={selectedTestimony}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </section>
  );
};

const TestimonyModal = ({ testimony, isOpen, onClose }) => {
  if (!isOpen || !testimony) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full"
      id="my-modal"
    >
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-bold text-gray-900">
            {testimony.name}
          </h3>
          <img
            src={testimony.picture}
            alt={`${testimony.name}'s profile`}
            className="mx-auto mt-4 h-24 w-24 rounded-full object-cover"
          />
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500 text-justify">
              {testimony.testimony}
            </p>
          </div>
          <div className="flex justify-center mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-[#198754] text-white text-base font-medium rounded-md w-2/3 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTestimonies;
