import React from "react";
import fnb from "../../assets/tipsAndTricks/fnb.jpg";

const FnB = () => {
  return (
    <section className="flex flex-col pt-5 w-full overflow-hidden items-center gap-14 text-black">
      <div className="about-visa">
        <div className="mx-14 poppins">
          <div className="flex justify-start mx-10">
            <div className=" w-[65px] h-[40px] bg-[#198754]" />
            <div className="poppins text-[#198754] m-5 text-2xl font-semibold relative w-fit mt-[-1.00px]">
              Foods and Beverages
            </div>
          </div>
          <div className="lg:flex justify-center gap-20 sm:mx-10 md:mx-10">
            <div className="poppins mt-10 font-normal">
              <p className="text-transparent mb-10 leading-loose">
                <span className="text-black">
                  Indonesia is famous for its rich taste in food and beverages.
                  However, due to usage of spices and other ingredients that
                  sometimes can only be found in Indonesia, we strongly suggest
                  always paying attention before consumption. Some people may
                  have digestion problems if the ingredients do not suit their
                  metabolism.
                  <br />
                </span>
              </p>
              <p className="font-normal text-transparent my-5">
                <p className="text-transparent mb-10 leading-loose">
                  <span className="text-black">
                    The tap water is{" "}
                    <span className="font-bold"> NOT CONSUMABLE </span> unless
                    it is stated otherwise.
                  </span>
                </p>
              </p>
            </div>
            <div className="flex flex-col items-center gap-10 mb-4">
              <div className="relative my-16">
                <div className="rounded-[30px] h-[40vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
                <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
                <img
                  className="absolute rounded-[30px] object-cover object-center h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
                  alt="FnB"
                  src={fnb}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FnB;
