import React from "react";

const Accomodation = () => {
  return (
    <div className="">
      <div className="frame-3 w-full px-4 mt-10 mb-20">
        <div className="m-10 poppins">
          <div className="flex justify-start mx-10">
            <div className=" w-[65px] h-[40px] bg-[#198754]" />
            <div className="poppins text-[#198754] m-5 text-2xl font-semibold relative w-fit mt-[-1.00px]">
              Accomodations
            </div>
          </div>
        </div>
        <div className="lg:flex justify-center m-10 leading-relaxed text-center">
          <p className="text-l px-10 py-7">
            <span className="text-wrapper-8 text-center">
              We have some recommendations regarding accommodation for the
              students. <br></br>Please refer to the attached "Recommendation of
              Accommodation" file on Google Drive below:
              <br />
            </span>
          </p>
        </div>
        <div className="flex justify-center mb-10 frame-4">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/document/d/1ACZn8adEoSuKj_GsR1bG91DQj4SKCz_od69wwyuMw-U/edit?usp=drive_link"
          >
            <div className="items-start bg-[#198754] rounded-lg inline-flex flex-col h-auto relative w-auto m-auto px-10 py-3 mt-10 text-s text-white font-thin">
              <b className="label-3 text-bold">Click Here</b>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Accomodation;
