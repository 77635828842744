import React from "react";
// import "./index.css";
import Navbar from "../../components/common/Navbar";
import Hero from "../../components/Hero";
import Footer from "../../components/common/Footer";
import AboutUI from "../../components/AboutUI";
import OurPrograms from "../../components/OurPrograms";
import AboutFKG from "../../components/AboutFKG";
import OurDepartements from "../../components/OurDepartements";
import Partners from "../../components/Partners";
import OurTestimonies from "../../components/OurTestimonies";
import OurActivities from "../../components/OurActivities";
import ActivitiesList from "../../components/ActivitiesList";
import AdjunctProfessor from "../../components/AdjunctProf";

let LandingPage = () => {
  return (
    <div className="landing-page ">
      <Navbar />
      <AboutFKG />
      <AdjunctProfessor />
      <OurPrograms />
      <OurDepartements />
      <Partners />
      <ActivitiesList />
      <OurTestimonies />
      <Footer />
    </div>
  );
};

export default LandingPage;
