import React from "react";
import "../pages/LecturerOutbound/style.css";
import sariesendy from "../assets/lecturer/sariesendy.png";
import sitiRahma from "../assets/lecturer/sitiRahma.png";
import Melissa from "../assets/lecturer/Melissa.png";

const RelationsUnit = () => {
  return (
    <div className="relations-unit">
      <div className="m-10">
        <div className="flex justify-center">
          <div className="flex flex-col gap-3 items-center">
            <h1 className="text-2xl font-semibold py-2 text-[#198754] sm:text-3xl">
              International Relations Unit
            </h1>
            <h3 className="text-lg py-2 text-black sm:text-xl">
              International Relations is responsible for all international
              activities at faculty level.
            </h3>
            <div className="w-28 bg-[#198754] h-2 rounded-xl" />
          </div>
        </div>
        <div className="items-center bg-[#f5f5f5] mb-20">
          <div className="mt-10 mb-17">
            <div className="font-normal text-black text-center pt-10">
              <span className="text-xl font-bold m-auto">
                Duties and Responsibilities:
              </span>
            </div>
            <div className="mt-14 font-normal text-black leading-loose px-5 mx-auto sm:px-10 lg:w-[60%]">
              <p>
                a. Receive visits from domestic and foreign
                universities.&nbsp;&nbsp;
                <br />
                b. Student exchange activities with various countries. <br />
                c. Teleconference activities to facilitate scientific
                discussions with foreign countries. <br />
                d. Comparative study visits from various countries and open
                correspondence for potential collaboration with abroad. <br />
                e. Providing information about overseas scholarship
                opportunities for undergraduate and postgraduate students.
                <br />
              </p>
            </div>

            <br />

            <div className="mt-14 font-normal text-black leading-loose px-10 lg:px-5 lg:w-[60%] mx-auto">
              <p className="text-black text-center max-w-screen-lg px-4">
                <span className="font-bold text-black">
                  Head of the International Relations Unit :{" "}
                </span>
              </p>
            </div>

            <div className="font-bold text-black text-center mt-4 mx-10 lg:flex justify-center gap-8">
              <div className="flex flex-col items-center p-5">
                <img
                  className="m-auto h-[20vh] lg:w-[10vw] w-[20vw] object-center object-cover rounded-xl"
                  alt="Drg. Melissa Adiatman, PhD"
                  src={Melissa}
                />
                <p className="mt-4 text-center flex-grow">
                  Drg. Melissa Adiatman, PhD
                  <br />
                  <span className="font-medium text-black">
                    (April 2014 - Maret 2022)
                  </span>
                </p>
              </div>
              <div className="flex flex-col items-center p-5">
                <img
                  className="m-auto h-[20vh] lg:w-[10vw] w-[20vw] object-center object-cover rounded-xl"
                  alt="Dr. drg. Sariesendy Sumardi, Sp.Ort(K)"
                  src={sariesendy}
                />
                <p className="mt-4 text-center flex-grow">
                  Dr. drg. Sariesendy Sumardi, Sp.Ort(K)
                  <br />
                  <span className="font-medium text-black">
                    (April 2022 - Oktober 2023)
                  </span>
                </p>
              </div>
            </div>

            <br />

            <div className="mt-14 font-normal text-black leading-loose px-10 lg:px-5 lg:w-[60%] mx-auto">
              <p className="text-black text-center max-w-screen-lg px-4">
                <span className="font-bold text-black">Staff : </span>
              </p>
            </div>
            <div className="font-bold text-black text-center mt-4 mx-10 lg:flex justify-center gap-8">
              <div className="flex flex-col items-center p-5">
                <img
                  className="m-auto h-[20vh] lg:w-[10vw] w-[20vw] object-center object-cover rounded-xl"
                  alt="Siti Nadya Ulfa, M.I.Kom"
                  src={sitiRahma}
                />
                <p className="mt-4 text-center flex-grow">
                  Siti Nadya Ulfa, M.I.Kom
                </p>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelationsUnit;
