import React from "react";
import FKGNew from "../../assets/common/FKGNew.png";

const CampusUI = () => {
  return (
    <div className="campus-ui">
      <div className="m-10">
        <div className="flex justify-center">
          <div>
            <div
              className="mb-1 md:leading-[72px] pt-2 md:text-3xl 
                            text-xl font-semibold text-[#198754]"
            >
              Universitas Indonesia Campus
            </div>
            <div className="rectangle" />
          </div>
        </div>
        <div className="items-center">
          <div className="mt-10 mb-5">
            <div className="font-normal text-black text-center">
              <span className="font-normal m-auto">
                Faculty of Dentistry Universitas Indonesia have 2 buildings in
                Salemba Campus and Depok Campus:
              </span>
            </div>
          </div>
        </div>
        <div className="lg:flex justify-center gap-20 sm:mx-10 md:mx-10">
          <div className="poppins mt-10 ml-20 font-normal my-auto">
            <p className="text-transparent mb-10 leading-loose">
              <p className="font-bold text-[#198754]">
                1. Faculty of Dentistry Salemba Campus
              </p>
              <p className="text-black">
                <span className="font-bold text-black">Address: </span>
                Universitas Indonesia Kampus, Jl. Salemba Raya IV No.2,
                RT.2/RW.5, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus
                Ibukota Jakarta 10430
              </p>
              <p className="text-black">
                <span className="text-black">See More: </span>
                <a
                  href="https://goo.gl/maps/GpebfWKKECJFSvVU9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold"
                >
                  Maps
                </a>
                <span> | </span>
                <a
                  href="https://fkg.ui.ac.id/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold"
                >
                  Website
                </a>
              </p>
            </p>
            <p className="text-transparent mb-10 leading-loose">
              <p className="font-bold text-[#198754]">
                2. Faculty of Dentistry Depok Campus
              </p>
              <p className="text-black">
                <span className="font-bold text-black">Address: </span>
                Universitas Indonesia Kampus, Rumpun Ilmu Kesehatan (RIK) Lantai
                2 - Pondok Cina, Kecamatan Beji, Kota Depok, Jawa Barat 16424
              </p>
              <p className="text-black">
                <span className="text-black">See More: </span>
                <a
                  href="https://goo.gl/maps/dTPBG5aoCeBj25vi9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold"
                >
                  Maps
                </a>
                <span className="text-black"> | </span>
                <a
                  href="https://www.ui.ac.id/en/academic/faculty/faculty-of-dentistry/ "
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold"
                >
                  Website
                </a>
                <span className="font-bold text-black"> | </span>
                <a
                  href="https://bikun.ui.ac.id/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-bold"
                >
                  Bikun Tracker
                </a>
              </p>
            </p>
          </div>
          <div className="relative my-16 ">
            <div className="ml-10 rounded-[30px] h-[50vh] w-[70vh] inset-0 items-start z-9 m-0" />
            <img
              className="absolute ml-12 lg:ml-24 mt-8 object-cover object-right h-auto w-[60vh] inset-0 items-center z-10"
              alt="FKGNew"
              src={FKGNew}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampusUI;
