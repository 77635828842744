import React from "react";
// import "./index.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

import CampusUI from "../../components/OurCampus/CampusUI";
import AboutUI from "../../components/AboutUI";
import RelationsUnit from "../../components/RelationsUnit";

let OurCampus = () => {
  return (
    <div className="student-exchange">
      <Navbar />
      <CampusUI />
      <RelationsUnit/>
      <AboutUI />
      <Footer />
    </div>
  );
};

export default OurCampus;
