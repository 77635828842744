import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaUniversity } from "react-icons/fa"; // Import a suitable icon

const UniversityList = ({ universities }) => {
  return (
    <div className="max-w-2xl mx-auto py-10">
      <ul className="space-y-4">
        {universities.map((university, index) => (
          <li
            key={index}
            className="flex items-center justify-between bg-white p-4 rounded-md shadow hover:shadow-lg transition-shadow duration-300"
          >
            <div className="flex items-center space-x-3">
              <FaUniversity className="text-lg text-[#198754]" />
              <span className="text-lg font-medium text-gray-700">
                {university}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Partners = () => {
  const universities = [
    "Chulalongkorn University",
    "Chung Shan Medical University",
    "Coorg Institute of Dental Sciences",
    "International Islamic University Malaysia",
    "Kagoshima University",
    "Khon Kaen University",
    "Mahidol University",
    "National Yang-Ming University",
    "Newcastle University",
    "Niigata University",
    "Radboud Universiteit Nijmegen",
    "Saveetha Institute of Medical and Technical Sciences",
    "The University of Melbourne",
    "The University of Sydney",
    "Tohoku University",
    "Tokyo Medical and Dental University",
    "Universiteit Leiden",
    "Universiti Kebangsaan Malaysia",
    "Universiti Teknologi MARA Malaysia",
    "University of Groningen",
    "University of Pennsylvania",
    "Vrije Universiteit Amsterdam",
  ];

  const universities1 = [
    "Chulalongkorn University",
    "Chung Shan Medical University",
    "Coorg Institute of Dental Sciences",
    "International Islamic University Malaysia",
    "Kagoshima University",
    "Khon Kaen University",
    "Mahidol University",
    "National Yang-Ming University",
    "Newcastle University",
    "Niigata University",
    "Radboud Universiteit Nijmegen",
  ];

  const universities2 = [
    "Saveetha Institute of Medical and Technical Sciences",
    "The University of Melbourne",
    "The University of Sydney",
    "Tohoku University",
    "Tokyo Medical and Dental University",
    "Universiteit Leiden",
    "Universiti Kebangsaan Malaysia",
    "Universiti Teknologi MARA Malaysia",
    "University of Groningen",
    "University of Pennsylvania",
    "Vrije Universiteit Amsterdam",
  ];

  return (
    <section className="flex flex-col py-16 px-4 gap-20 items-center">
      <div className="w-full max-w-screen-xl flex items-center gap-5">
        <div className="w-20 h-10 bg-[#198754]" />
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
          Our University Partners
        </h1>
      </div>
      <div className="bg-[#f2f2f2] text-black px-8 rounded-md shadow-md w-full md:w-10/12 mx-auto hidden xl:block">
        <div className="flex flex-col md:flex-row justify-around items-center gap-8 md:items-start">
          <div className="flex-1 md:mr-2">
            <UniversityList universities={universities1} />
          </div>
          <div className="flex-1 md:ml-2">
            <UniversityList universities={universities2} />
          </div>
        </div>
      </div>

      <div className="bg-[#f2f2f2] text-black p-6 rounded-md shadow-md w-full md:w-10/12 mx-auto xl:hidden">
        <div className="flex flex-col md:flex-row justify-around items-center md:items-start">
          <UniversityList universities={universities} />
        </div>
      </div>
    </section>
  );
};

export default Partners;
