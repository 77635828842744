import React from "react";
import UI from "../assets/homepage/whyuiHero.jpg";
import UIDark from "../assets/homepage/whyui-dark.png";

const AboutUI = () => {
  return (
    <section className="w-full bg-[#f6f6f6] text-black py-16 px-4 ">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col items-center gap-10 px-10 w-fit">
          <div className="aspect-video w-full relative">
            <div className="aspect-video w-full bg-[#199064] absolute -top-10 left-10 overflow-hidden rounded-3xl ">
              <img className="w-full object-contain " src={UIDark} alt="ui" />
            </div>
            <div className="aspect-video bg-[#199064] w-full absolute top-10 -left-10 overflow-hidden rounded-3xl ">
              <img className="w-full object-contain " src={UIDark} alt="ui" />
            </div>
            <div className="aspect-video w-full relative overflow-hidden rounded-3xl">
              <img className="w-full object-contain " src={UI} alt="ui" />
            </div>
          </div>
          <button className="bg-[#198754] text-white w-[150px]  rounded-xl font-normal my-6 mx-auto md:mx-0 py-3">
            <a href="https://www.ui.ac.id/en/" target="_blank" rel="noreferrer">
              SEE MORE
            </a>
          </button>
        </div>
        <div className="flex flex-col items-end gap-8 sm:p-5 sm:m-3 md: text-end">
          <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
            About Universitas Indonesia
          </h1>
          <div className="flex flex-col font-thin gap-5 max-w-lg">
            <p>
              UI is one of the leading research universities or academic
              institutions in the world that continues to pursue the highest
              achievements in terms of discovery, development and knowledge
              diffusion regionally and globally.
            </p>
            <p>
              With the achievements it continues to achieve, UI is ranked as the
              best campus in Indonesia based on an assessment by a world ranking
              agency.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUI;
