import React from "react";
import "../pages/LecturerOutbound/style.css";
import visaImg from "../assets/lecturer/visa.png";

const Visa = () => {
  return (
    <div className="about-visa">
      <div className="m-10 poppins p-10">
        <div className="w-full max-w-screen-xl flex items-center gap-5">
          <div className="w-20 h-10 bg-[#198754]" />
          <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
            About Visa
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center mx-10 my-16 lg:flex-row lg:justify-start lg:gap-20">
          <div className="relative my-16">
            <div className="rounded-[30px] h-[40vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
            <div className="absolute top-4 -left-4 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-start z-9 m-0" />
            <div className="place-items-end absolute -top-4 left-10 bg-[#198254] rounded-[30px] h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 z-8 m-0" />
            <img
              className="absolute rounded-[30px] object-cover object-center h-[35vh] w-[60vw] lg:h-[25vh] lg:w-[35vh] inset-0 items-center z-10"
              alt="Visa"
              src={visaImg}
            />
          </div>
          <div className="poppins mt-10 text-center lg:text-left lg:mt-0">
            <p className="text-transparent mb-10 leading-loose font-semibold">
              <span className="font-bold text-[#198754]">
                The Indonesian Government
              </span>
              <span className="text-black"> has&nbsp;</span>
              <span className="font-bold text-black underline">
                updated the list of ports of entry{" "}
              </span>
              <span className="font-bold text-[#198754] underline">
                <a
                  href="https://kemlu.go.id/download/L1NoYXJlZCUyMERvY3VtZW50cy9TZ3BGaWxlRG9jLzZBcHJpbEltaWdyYXNpMjAyMi5wZGY="
                  target="_blank"
                  rel="noreferrer"
                >
                  (click here)
                </a>
              </span>

              <span className="text-black">
                {" "}
                for Visa on Arrival and Visa Exemption Arrangement Facility
                Holders for Tourism Purpose (updated on 6 April 2022).
                <br />
              </span>
            </p>
            <p className="font-normal text-transparent my-5">
              <span className="font-bold text-[#198754] mb-3">
                Republic of Indonesia Visa Application For Short Training
                Student{" "}
                <span className="text-black">
                  <a
                    href="https://www.imigrasi.go.id/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (see more)
                  </a>
                </span>
                <br />
              </span>
              <div className="leading-loose">
                <span className="text-black">
                  For the student visa, you will find the attached link to a
                  Google Drive{" "}
                </span>
                <span className="text-black">
                  {" "}
                  that consists of a list of required documents to apply for the
                  visa. We will update you should there be any changes to the
                  required documents. Students will be using the visa for Short
                  Courses with a validity of 60 days with the cost of IDR
                  2,000,000 per person. <br />
                  <br />A visa is{" "}
                  <span className="font-bold">NOT required</span> for (example)
                  Cambodian passport holders visiting Indonesia for a short-term
                  stay: 30 days.
                  <br />
                  <br />
                  Student Visa Informations:
                  <span className="text-black font-semibold">
                    <a
                      href="https://docs.google.com/document/d/1-9erWNfx3UZ9xZ0pYoHHA9SaWRnLtmDvXrl_WZ_ubzo/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      (see more)
                    </a>
                  </span>
                </span>
              </div>
            </p>
            <p className="font-normal text-transparent my-5">
              <span className="font-bold text-[#198754] mb-3">
                Republic of Indonesia Visa Application For Long Training Student{" "}
                <span className="text-black">
                  <a
                    href="https://www.imigrasi.go.id/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (see more)
                  </a>
                </span>
                <br />
              </span>
              <div className="leading-loose">
                <span className="text-black">
                  If your arrival is more than 30 days, you need to make a
                  Student Visa (for students). Applications can be made by
                  sending the required files (the files are in the Google Drive
                  link: Student Visa Information Folder), then sending them to
                  the email{" "}
                  <span className="text-[#198754]">io.dent@ui.ac.id</span> , and
                  then HI FKG UI will continue the Visa application to The UI
                  International Affairs Office will then process the visa at
                  Immigration.
                </span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visa;
