import Card from "./Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OralBiology from "../assets/homepage/departments/oralBiology.png";
import OralMedicine from "../assets/homepage/departments/oralMedicine.png";
import Prostodonsia from "../assets/homepage/departments/prostodonsia.png";
import Maklofasial from "../assets/homepage/departments/maksilofasial.png";
import Conservative from "../assets/homepage/departments/conservativeDentistry.png";
import Ortodonsia from "../assets/homepage/departments/ortodonsia.png";
import Pedodontics from "../assets/homepage/departments/pedondontics.png";
import Pencegahan from "../assets/homepage/departments/pencegahan.png";
import Material from "../assets/homepage/departments/materialDentistry.png";
import Radiologi from "../assets/homepage/departments/radiologi.png";
import Periodonsia from "../assets/homepage/departments/periodonsia.png";

const OurDepartements = () => {
  return (
    <section className="flex flex-col w-full py-20 px-4 overflow-hidden sm:px-8 items-center gap-14 text-[#198754] ">
      <div className="w-full max-w-screen-xl flex items-center gap-5">
        <div className="w-20 h-10 bg-[#198754]" />
        <h1 className="md:text-2xl sm:text-3xl text-2xl font-semibold py-2 text-[#198754]">
          Our Departments
        </h1>
      </div>
      <div className="flex  bg-[#dbdbdb] max-w-screen-xl rounded-2xl w-full mx-auto gap-10 px-2 sm:px-20 py-10">
        <div className="flex justify-center items-center w-full gap-10 flex-row flex-wrap">
          <Slider
            infinite
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            draggable
            className="w-full"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <Card
              title={"Department of Oral Biology"}
              href={"https://fkg.ui.ac.id/en/department/biologi-oral/"}
              picture={OralBiology}
            />
            <Card
              title={"Department of Dental Material"}
              href={
                "https://fkg.ui.ac.id/en/department/ilmu-material-kedokteran-gigi/"
              }
              picture={Material}
            />
            <Card
              title={"Department of Radiology"}
              href={
                "https://fkg.ui.ac.id/en/department/radiologi-kedokteran-gigi/"
              }
              picture={Radiologi}
            />
            <Card
              title={"Department of Oral & Maxillofacial Surgery"}
              href={"https://fkg.ui.ac.id/en/department/ilmu-bedah-mulut/"}
              picture={Maklofasial}
            />
            <Card
              title={"Department of Pedodontics"}
              href={
                "https://fkg.ui.ac.id/en/department/ilmu-kedokteran-gigi-anak/"
              }
              picture={Pedodontics}
            />
            <Card
              title={"Department of Community Dentistry - Prevention"}
              href={
                "https://fkg.ui.ac.id/en/department/ilmu-kedokteran-gigi-masyarakat-pencegahan/"
              }
              picture={Pencegahan}
            />
            <Card
              title={"Department of Conservative Dentistry"}
              href={"https://fkg.ui.ac.id/en/department/ilmu-konservasi-gigi/"}
              picture={Conservative}
            />
            <Card
              title={"Department of Oral Medicine"}
              href={"https://fkg.ui.ac.id/en/department/ilmu-penyakit-mulut/"}
              picture={OralMedicine}
            />
            <Card
              title={"Department of Orthodontics"}
              href={"https://fkg.ui.ac.id/en/department/ortodonsia/"}
              picture={Ortodonsia}
            />
            <Card
              title={"Department of Periodontics"}
              href={"https://fkg.ui.ac.id/en/department/periodonsia/"}
              picture={Periodonsia}
            />
            <Card
              title={"Department of Prosthodontics"}
              href={"https://fkg.ui.ac.id/en/department/prostodonsia/"}
              picture={Prostodonsia}
            />
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default OurDepartements;
